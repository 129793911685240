import classes from './NotFoundPage.module.css';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <div dir="rtl" className={classes.container}>
            <h1 className={classes.title}>
                404
            </h1>
            <p style={{ fontSize: '40px', height: "100%" , display: "flex" , justifyContent: "center", alignItems: "center", position: "relative" , textAlign: 'center' }}
            >
                נראה שהלכת לאיבוד...
            </p>

            <button onClick={() => {
                navigate('/')
            }} className={classes.button}>
                חזרה לדף הבית
            </button>
        </div>
    )
}
export default NotFoundPage;