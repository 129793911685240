//Components
import { Container, Typography, IconButton, Grid, Button } from "@mui/material";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import CssTextField from "./KeyInfoFormUtils";

import React, { useState } from "react";

//Hooks
import { useRef } from "react";
import useValidateKey from "../hooks/useValidateKey";

const KeyInfoForm = (props) => {
  const [hasError, validation, isKeyValid] = useValidateKey();

  const keyInputRef = useRef();

  const onChangeInputHandler = (e) => {
    const enteredValue = keyInputRef.current.value;
    validation(enteredValue);
  };

  const onSubmitFormHandler = (e) => {
    const key = keyInputRef.current.value;
    if (hasError || !key) return;

    fetch(`https://api.spotifyman.com/?info=${encodeURIComponent(key)}`).then((res) => {
      return res.json().then((result) => {
        props.setData(result);
      })
    }).catch(console.log);
  };

  return (
    <Container dir="rtl" maxWidth="md">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CssTextField
            onChange={onChangeInputHandler}
            inputRef={keyInputRef}
            label="מפתח"
            id="custom-css-outlined-input"
          />
          {hasError && (
            <Typography
              sx={{ marginTop: "10px", color: "red" }}
              align="center"
              variant="subtitle1"
            >
              אנא הכנס מפתח תקף
            </Typography>
          )}
          <Button
            onClick={onSubmitFormHandler}
            variant="contained"
            sx={{ marginTop: "15px",":disabled": {
            color: "white",
            backgroundColor: "#1a2632",
          } }}
            disabled={!isKeyValid}
          >
            בדיקה
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default KeyInfoForm;
