import { Typography, IconButton } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import React from "react";
import UpgradePage from "./UpgradePage";

const RenewPage = () => {

  return (
    <div dir="rtl">
      <IconButton disabled={true} sx={{ width: "100%", margin: "auto" }}>
        <AutorenewIcon sx={{ fontSize: "100px", color: "white" }} />
      </IconButton>
      <Typography variant="h4" align="center" gutterBottom>
        חידוש 
      </Typography>
      <UpgradePage renew={true} />
    </div>
  );
};

export default RenewPage;
