import {useState, useRef} from 'react';

const useValidate = (validate) => {
    const [isInputValid, setIsInputValid] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [inputValue, setInputValue] = useState('') 

    const hasError = isTouched && !isInputValid;
    const inputRef = useRef()

    const onChangeHandler = (value, options) => {
        let countryCode;
        if(options){
            countryCode = options.data.countryCode;
            setInputValue(countryCode);
        } else {
            setInputValue(value);
        }
        if(!value){
            return setIsInputValid(false)
        }
        
        const valid = validate(value.trim());
        if(valid){
            return setIsInputValid(true);
        } else {
            return setIsInputValid(false)
        }
        
    }

    const onBlurHandler = () => {
        setIsTouched(true)
    } 

    return {onBlurHandler, onChangeHandler, hasError, inputRef, isInputValid, inputValue}
}

export default useValidate;