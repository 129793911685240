import {
  Container,
  InputAdornment,
  IconButton,
  FilledInput,
  FormControl,
  InputLabel,
  Button,
  Typography,
} from "@mui/material";
import React from "react";
import CssTextField from "../UI/KeyInfoFormUtils";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SelectCountry from "../UI/SelectCountry";
import UpgradeMessage from "../UI/UpgradeMessage";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import RenewMessage from "../UI/RenewMessage";

import { useRef, useState } from "react";
import useValidateKey from "../hooks/useValidateKey";
import useValidate from "../hooks/useValidate";

const styles = (theme) => ({
  disabledButton: {
    backgroundColor: theme.palette.primary || "red",
  },
});

const UpgradePage = ({ renew }) => {
  const [responseData, setResponseData] = useState();
  const keyInputRef = useRef();

  const {
    onBlurHandler: onBlurContryHandler,
    onChangeHandler: onChangeCountryHandler,
    inputRef: countryInputRef,
    hasError: countryHasError,
    isInputValid: isCountryIputValid,
    inputValue: countryInputValue,
  } = useValidate((value) => {
    if (value.length === 0) {
      return false;
    }
    return true;
  });

  const {
    onBlurHandler: onBlurPasswordHandler,
    onChangeHandler: onChangePasswordHandler,
    inputRef: passwordInputRef,
    hasError: passwordHasError,
    isInputValid: isPasswordIputValid,
  } = useValidate((value) => {
    if (value.length === 0) {
      return false;
    }
    return true;
  });

  const {
    onBlurHandler: onBlurUsernameHandler,
    onChangeHandler: onChangeUsernameHandler,
    inputRef: usernameInputRef,
    hasError: usernameHasError,
    isInputValid: isUsernameIputValid,
  } = useValidate((value) => {
    if (value.length === 0) {
      return false;
    }
    return true;
  });

  const [keyHasError, validation, isKeyValid] = useValidateKey();

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const onSubmitFormHandler = () => {
    if (keyHasError) return;

    const dataForm = {
      key: keyInputRef.current.value,
      login: usernameInputRef.current.value,
      password: passwordInputRef.current.value,
      countryCode: countryInputValue,
    };
    let url;

    if (renew) {
      url = `?renew=${encodeURIComponent(dataForm.key)}&login=${encodeURIComponent(dataForm.login)}&pwd=${encodeURIComponent(dataForm.password)}`;
    } else {
      url = `?upgrade=${encodeURIComponent(dataForm.key)}&login=${encodeURIComponent(dataForm.login)}&pwd=${encodeURIComponent(dataForm.password)}&country=${encodeURIComponent(dataForm.countryCode)}`;
    }
    fetch(`https://api.spotifyman.com/${url}`)
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        console.log(result)
        setResponseData(result);
      })
      .catch(console.log);
  };

  let isFormValid;
  if (renew) {
    isFormValid = isPasswordIputValid && isUsernameIputValid && isKeyValid;
  } else {
    isFormValid =
      isPasswordIputValid &&
      isUsernameIputValid &&
      isKeyValid &&
      isCountryIputValid;
  }

  return ( 
    <Container maxWidth="md">
      {!renew && (
        <IconButton disabled={true} sx={{ width: "100%", margin: "auto" }}>
          <UpgradeIcon sx={{ fontSize: "100px", color: "white" }} />
        </IconButton>
      )}
      {!renew && (
        <Typography variant="h4" align="center" gutterBottom>
          שדרוג
        </Typography>
      )}
      {responseData && !renew && <UpgradeMessage data={responseData} />}
      {renew && responseData && <RenewMessage data={responseData}/>} 
      <CssTextField
        label="מפתח"
        id="custom-css-outlined-input"
        onChange={() => {
          const value = keyInputRef.current.value;

          validation(value);
        }}
        inputRef={keyInputRef}
      />
      <hr />
      <FormControl sx={{ margin: "10px 0 20px 0" }} fullWidth color="secondary">
        <InputLabel sx={{ color: "white" }} htmlFor="Username">
          אימייל או שם משתמש
        </InputLabel>
        <FilledInput
          onChange={() => {
            let value = usernameInputRef.current.value;

            onChangeUsernameHandler(value);
          }}
          onBlur={onBlurUsernameHandler}
          inputRef={usernameInputRef}
          sx={{ color: "white", borderColor: "white" }}
          id="Username"
        />
      </FormControl>

      <FormControl fullWidth color="secondary">
        <InputLabel sx={{ color: "white" }} htmlFor="filled-adornment-password">
          סיסמה
        </InputLabel>
        <FilledInput
          onChange={(e) => {
            let value = passwordInputRef.current.value;

            handleChange("password")(e);
            onChangePasswordHandler(value);
          }}
          onBlur={onBlurPasswordHandler}
          inputRef={passwordInputRef}
          sx={{ color: "white", borderColor: "white" }}
          id="filled-adornment-password"
          type={values.showPassword ? "text" : "password"}
          value={values.password}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="מתג נראות סיסמה"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {renew ? (
        ""
      ) : (
        <SelectCountry
          onBlurContryHandler={onBlurContryHandler}
          onChangeCountryHandler={onChangeCountryHandler}
          isCountryIputValid={isCountryIputValid}
        />
      )}
      <Button
        onClick={onSubmitFormHandler}
        sx={{
          margin: "20px auto",
          ":disabled": {
            color: "white",
            backgroundColor: "#1a2632",
          },
        }}
        variant="contained"
        disabled={!isFormValid}
      >
        {renew ? "חידוש" : "שדרוג"}
      </Button>
    </Container>
  );
};

export default UpgradePage;
