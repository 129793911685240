import {useState} from 'react';

const useValidateKey = () => {
    const [isKeyValid, setIsKeyValid] = useState(false)
    const [hasError, setHasError] = useState(false);

    const validateKeyFunc = (value) => {
        const enteredValue = value.trim();
        const numOfDashes = enteredValue.split("-");

        if (enteredValue.length !== 19 || numOfDashes.length !== 4) {
            setIsKeyValid(false)
            return setHasError(true);
          }
          setIsKeyValid(true)
          return setHasError(false);
    } 
    
    return [hasError, validateKeyFunc, isKeyValid];
}
export default useValidateKey;