import React, {useEffect, useState} from 'react';

//Components
import Navigation from "./Layout/Navigation";
import HomePage from "./Pages/HomePage";
import { Routes, Route } from "react-router-dom";
import KeyInfoPage from "./Pages/KeyInfoPage";
import UpgradePage from "./Pages/UpgradePage";
import RenewPage from "./Pages/RenewPage";
import { useLocation } from "react-router-dom";
import Footer from './Layout/Footer';
import NotFoundPage from './Pages/NotFoundPage';

// Material UI
import { ThemeProvider, createTheme } from "@mui/material/styles";

const tabs = [
  { tab: "בית", route: "/" },
  { tab: "בדיקת מפתח", route: "/key-info" },
  { tab: "שדרוג", route: "/upgrade" },
  {tab: 'חידוש', route: '/renew'}
];

function App() {
  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: 'Alef'
      }
    }
 });

  const location = useLocation()
  const initialRoute = location.pathname;
  
  let foundTabIndex = tabs.findIndex(tab => tab.route === initialRoute);
  if(foundTabIndex === -1){
    foundTabIndex = 0;
  }

  const [value, setValue] = useState(foundTabIndex);

  useEffect(() => {
    if(tabs[value].route === location.pathname) return;

    setValue(preValue => {
      if (tabs[preValue].route !== location.pathname) {
        let updatedPath = tabs.findIndex(tab => tab.route === location.pathname);
        if(updatedPath === -1){
          updatedPath = 0;
        }
        return updatedPath;
      };
    })
  }, [location])


  return (
<>
      <ThemeProvider theme={theme}>
      <Navigation value={value} setValue={setValue} tabs={tabs}/>
      <div style={{height: '100%', position: 'relative'}}>
      <Routes>
        <Route path="/" element={<HomePage value={value} setValue={setValue}/>}/>

        <Route path="/key-info" element={<KeyInfoPage/>}/>

        <Route path="/upgrade" element={<UpgradePage/>}/>

        <Route path="/renew" element={<RenewPage/>}></Route>

        <Route path='*' element={<NotFoundPage/>}/>
      </Routes>
      </div>
      <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
    <Footer/>
    </div>
    </ThemeProvider>
   
    </>
  );
}

export default App;
