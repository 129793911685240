import { Tabs, Tab } from "@mui/material";

const WideDeviceNavigation = ({ navigateRoute, value, tabs }) => {
  return (
    <>
      <Tabs
        textColor="secondary"
        indicatorColor="secondary"
        value={value}
        onChange={navigateRoute}
      >
      {tabs.map((tab, index) => {
        return <Tab key={index} sx={{color: 'white'}} label={tab.tab} />
      })}
      </Tabs>
    </>
  );
};

export default WideDeviceNavigation;
