import { Typography } from "@mui/material";
import React from "react";

const RenewMessage = ({ data }) => {
  let { status, message } = data;
  let translate_message = false;

  switch (status) {
    case "not_exist":
      status = "מפתח לא קיים במערכת";
      message = "";
      break;
    case "success":
      status = "בקשת חידוש נשלחה";
      message = "אנחנו בודקים את בקשת החידוש, אנא בדוק את מצב המפתח בעוד כמה רגעים"
      break;
    case "error":
      status = "שגיאה"
      translate_message = true;
      break;
    default:
      status = "שגיאה";
      message = "אנא פנה לתמיכה דרך שרת הדיסקורד";
      break;
  }

  return (
    <div dir="rtl">
    <Typography variant="h5" gutterBottom align="center">
        סטטוס: <span style={{color: '#b3b3b3'}}>{status}</span>
    </Typography>
    {(message !== 'none' && message !== '') && <Typography variant="h5" align="center"> הודעת מערכת: <span style={{color: '#b3b3b3'}}>{message}</span> </Typography> }
    </div>
  );
};

export default RenewMessage;
