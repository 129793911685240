//Hooks
import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

//Components
import { AppBar, Toolbar, Typography, Box, IconButton } from "@mui/material";
import SmallDeviceNavigation from "./SmallDevicesNavigation";
import WideDeviceNavigation from "./WideDeviceNavigation";



const Navigation = ({value, setValue, tabs}) => {
  
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const isWideDevice = useMediaQuery("(min-width: 772px)");
  const textIsBig = useMediaQuery("(max-width: 552px)");

  const navigateRouteHandler = (e, newValue) => {
    setValue(newValue);
    const route = tabs[newValue].route;
    navigate(route);
    setOpen(false);
  };

  return (
    <Box sx={{ marginBottom: "30px" }}>
      <AppBar sx={{ marginBottom: "20px" }} position="static">
        <Toolbar sx={{ backgroundColor: "#262626", padding: "17px" }}>
          <IconButton></IconButton>
          <Typography
            variant={textIsBig ? 'h4' : "h3"}
            sx={{
              color: "white",
              flexGrow: 1,
              fontFamily: 'Alef, sans-serif'
            }}
          >
            איש הספוטיפיי 🧙‍♂️
          </Typography>
          {isWideDevice ? (
            <WideDeviceNavigation
              navigateRoute={navigateRouteHandler}
              value={value}
              tabs={tabs}
            />
          ) : (
            <SmallDeviceNavigation
              open={open}
              setOpen={setOpen}
              navigateRoute={navigateRouteHandler}
              value={value}
              tabs={tabs}
            />
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navigation;
