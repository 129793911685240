import { Typography, Button, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./HomePage.module.css";

import { ThemeProvider, createTheme } from "@mui/material/styles";

const HomePage = ({ setValue }) => {
  const mediumDevice = useMediaQuery("(max-width: 900px)");
  const navigate = useNavigate();

  let title = "h3";
  let fontSize = "18px";

  let titleWidth = "60%";
  let subtitleWidth = "40%";

  let imagePosition = { top: "50%", left: "100px", position: "absolute" };
  let logoWidth = "350px";

  if (mediumDevice) {
    title = "h4";
    titleWidth = "70%";
    subtitleWidth = "70%";
    fontSize = "16px";
    imagePosition = { bottom: "0", position: "relative" };
    logoWidth = "250px";
  }

  return (
    <Container maxWidth="xl">
      <div style={{ height: "100%", width: "100%" , display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
        <div>
          <Typography
            sx={{ height: "100%", width: "100%" , display: "flex", justifyContent: "center", alignItems: "center", position: "relative",
            marginBottom: "25px" }}
            variant={title}
          >
            <b>
              נמאס לך לשלם כל חודש על ספוטיפיי פרימיום?
            </b>
          </Typography>

          <Typography
            gutterBottom
            sx={{fontSize: fontSize, 
              marginBottom: "20px"
            }}
            variant="subtitle1"
          >
            אנחנו מאמינים שלא צריך לשלם חודשי עבור שירות פשוט כמו מוזיקה, בנינו מערכת אוטומטית כדי להפוך את החיים שלך לפשוטים יותר.
          </Typography>

          <Typography
            sx={{
              height: "100%", width: "100%" , display: "flex", justifyContent: "center", alignItems: "center", position: "relative" ,
              fontSize: "18px",
              marginBottom: "25px",
            }}
            gutterBottom
            variant="subtitle1"
          >
          במחיר נמוך של&nbsp;<span style={{ color: "yellow" }}><b>₪49.50</b></span>&nbsp;למנוי
          </Typography>

          <div>
            <div
              style={{
                height: "100%", width: "100%" , display: "flex", justifyContent: "center", alignItems: "center", position: "relative"
              }}
            >
              <button
              
              onClick={() => {
                  navigate("/upgrade");
                  setValue(2);
                }}
                className={classes.upgradeBtn}
              >
                <b>שדרוג</b>
              </button>

              <button
                data-sellix-product="64828e6c2c0fa"
                type="submit"
                alt="Buy Now with sellix.io"
                className={classes.purchaseBtn}
              >
                <b>רכישה</b>
              </button>
            </div>
            <div style={{
                height: "100%", width: "100%" , display: "flex", marginTop: "10px" , justifyContent: "center", alignItems: "center", position: "relative"
              }}
            >
              <button

              onClick={() => {
                window.open("https://www.instagram.com/the.spotify.man/")
              }}
                className={classes.supportBtn}
              >
              <b>תמיכה</b>
              </button>
            </div>
          </div>
            {/* <img style={{ width: logoWidth, height: "100%", display: "flex", justifyContent: "center", alignItems: "center", position: "relative"}} src="Logo.png" /> */}
        </div>
      </div>
      <div
          style={{
            ...imagePosition,
            margin: "50px",
            display: "flex",
            justifyContent: "center",
          }}
        >
        </div>
    </Container>
  );
};

export default HomePage;
