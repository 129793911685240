import React, { useEffect, useState } from "react";
import axios from "axios";

//Components
import { Autocomplete, TextField, Box } from "@mui/material";
const SelectCountry = ({countryInputRef, onBlurContryHandler, onChangeCountryHandler}) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetch("https://api.spotifyman.com/?stock")
      .then(function (response) {
        return response.text();
      })
      .then((data) => {
        const givenCountries = JSON.parse(data);
        const formattedCountries = givenCountries.map((c) => {
          return { label: c.country, code: c.country_code, stock: c.slots };
        });
        setCountries(formattedCountries);
      })
      .catch(console.log);

  }, []);
  return (
    <div style={{ width: "50%", margin: "20px auto" }}>
      <Autocomplete
      onChange={(e, value) => {
        if(!value){
          return onChangeCountryHandler(null)
        }
        return onChangeCountryHandler(value.label, {data:  {countryCode: value.code}})
        }}
      // onBlur={onBlurContryHandler}
        options={countries}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.label}
          </Box>
        )}
        renderInput={(params) => {
          return (
            <>
              <TextField
                sx={{ label: { color: "white" }, input: { color: "white" } }}
                {...params}
                label="בחר מדינת חשבון"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            </>
          );
        }}
      ></Autocomplete>
    </div>
  );
};

export default SelectCountry;
