import React from "react";
import { Typography } from "@mui/material";

const KeyInfoDisplay = ({ data }) => {
  let usable = "לא";
  let { status, used_date, message } = data;
  let translate_message = false;

  switch (status) {
    case "failed":
      status = "שומם";
      usable = "כן";
      break;
    case "idle":
      status = "שומם"
      usable = "כן";
      break;
    case "not_exist":
      status = "מפתח לא קיים במערכת";
      message = "";
      break;
    case "success":
      status = "חשבון שודרג בהצלחה";
      message = "";
      break;
    case "error":
      status = "שגיאה";
      translate_message = true;
      break;
    case "upgrade_processing":
      status = "שדרוג בהתליך";
      message = "בדוק שוב בעוד כמה רגעים"
      break;
    case "failed_upgrade":
      status = "שדרוג נכשל";
      translate_message = true;
      break;
    case "success_renew":
      status = "חשבון חודש בהצלחה"
      message = "אנא המשך לשדרוג"
      usable = "כן";
      break;
    case "failed_renew":
      status = "חידוש נכשל";
      translate_message = true;
      break;
    case "check_processing":
      status = "בדיקה בתהליך";
      message = "בדוק שוב בעוד כמה רגעים"
      break;
    case "in_queue":
      status = "שדרוג בתהליך";
      message = "בדוק שוב בעוד כמה רגעים"
      break;
    default:
      status = "שגיאה";
      message = "אנא פנה לתמיכה דרך שרת הדיסקורד";
      break;
  }

  if(!used_date){
    used_date = new Date('01-01-1970')
  } else {
    used_date = new Date(used_date)
  }

  return (
    <div dir="rtl" style={{}}>
      <hr />
      <Typography sx={{ fontSize: "24px" }} variant="h6" align="center">
        סטטוס: <span style={{color: '#bfbfbf'}}>{status}</span>
      </Typography>
      {(message !== 'none' && message !== '') && <Typography sx={{ fontSize: "20px" }} variant="h6" align="center">
        הודעת מערכת: <span style={{color: '#bfbfbf'}}>{message}</span>
      </Typography>}
      <Typography sx={{ fontSize: "20px" }} variant="subtitle1" align="center">
        ניתן לשימוש: <span style={{color: '#bfbfbf'}}>{usable}</span>
      </Typography>
      <Typography
        sx={{ fontSize: "20px" }}
        gutterBottom
        variant="subtitle1"
        align="center"
      >
        שימוש אחרון: <span style={{color: '#bfbfbf'}}>{used_date.getTime() <= 0 ? "אף פעם" : used_date.toLocaleString()}</span>
      </Typography>
    </div>
  );
};

export default KeyInfoDisplay;
