import React, { useState } from "react";

//Components

import KeyInfoForm from "../UI/KeyInfoForm";
import KeyInfoDisplay from "../UI/KeyInfoDisplay";

import { Typography, IconButton } from "@mui/material";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

const KeyInfoPage = () => {
  const [data, setData] = useState(null);

  return (
    <div dir="rtl" style={{ height: "100%", width: '87%', margin: 'auto' }}>
      <IconButton disabled={true} sx={{ width: "100%", margin: "auto" }}>
        <ContentPasteSearchIcon sx={{ fontSize: "100px", color: "white" }} />
      </IconButton>
      <Typography variant="h4" align="center">
        בדיקת מפתח
      </Typography>

      {data && <KeyInfoDisplay data={data} />}
      <KeyInfoForm setData={setData} />
    </div>
  );
};

export default KeyInfoPage;
