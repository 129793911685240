import {
    Tabs,
    Tab,
    IconButton,
    Drawer,
  } from "@mui/material";

  import MenuIcon from '@mui/icons-material/Menu';

const SmallDeviceNavigation = ({ navigateRoute, value, open, setOpen, tabs }) => {
    return (
      <>
        <IconButton
          onClick={() => setOpen(true)}
        sx={{marginLeft: '10px'}}
          size="large"
          edge="start"
          aria-label="menu"
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Drawer onClose={() => setOpen(false)} open={open} anchor={"left"}>
          <Tabs
            onChange={navigateRoute}
            textColor="secondary"
            indicatorColor="secondary"
            orientation="vertical"
            variant="scrollable"
            aria-label="Vertical tabs example"
            value={value}
            sx={{ borderRight: 1, borderColor: "divider", padding: "20px", backgroundColor: '#262626', height: '100%' }}
          >
          {tabs.map((tab, index) => {
            return <Tab key={index} sx={{color: 'white'}} label={tab.tab} />
          })}
          </Tabs>
        </Drawer>
      </>
    );
  };

  export default SmallDeviceNavigation;