import { Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const Footer = () => {
  const smallText = useMediaQuery("(max-width: 460px)");
  let variant = "subtitle1";
  if (smallText) {
    variant = "subtitle2";
  }

  // return (
  //   <div dir="rtl" style={{ position: "fixed", bottom: 0 }}>
  //     {<Typography gutterBottom variant={variant} align="center">
  //       Website Created & Designed by {" "}
  //       <a
  //         style={{ fontSize: "16px", fontWeight: "bold", color: '#3366ff' }}
  //         href="https://shidro.me/"
  //       >
  //       Adam
  //       </a>
  //     </Typography> }
  //     <div style={{ position: "fixed", bottom: "10px", right: "10px" }}>
  //       <a href="https://discord.gg/QPcXVkPxRB" target="_blank">
  //         <img
  //           width={"80px"}
  //           height={"80px"}
  //           src="https://cdn-icons-png.flaticon.com/512/5968/5968968.png"
  //         />
  //       </a>
  //     </div>
  //   </div>
  // );
};
export default Footer;
